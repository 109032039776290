import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import {FunctionService} from "../demo/service/function.service";

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];
	user:any;

    constructor(public layoutService: LayoutService, private functions: FunctionService) { }

    ngOnInit() {
		if (this.functions.getLocallData()) {
            this.user = this.functions.getLocallData();
            console.log(this.user.rol);
			if(this.user.rol === 'Administrador' || this.user.rol === 'Super administrador'){
				    this.model = [
								{
									label: 'Competencia', icon: 'pi pi-bolt',
									items: [
										{ label: 'Administración de Competencias', icon: 'pi pi-cog', routerLink: ['/competition'] },
										{ label: 'Visualizar Información', icon: 'pi pi-database', routerLink: ['/competition/data'] },
									]
								},
                                {
									label: 'Horarios', icon: 'pi pi-calendar',
									items: [
										{ label: 'Crear Horarios', icon: 'pi pi-clock', routerLink: ['/schedules/create'] },
                                        { label: 'Visualizar Información', icon: 'pi pi-database', routerLink: ['/competition/schedules'] },
									]
								},
								{
									label: 'Asistencia',
									icon: 'pi pi-check-square',
                                    items: [
										{ label: 'Asistencia', icon: 'pi pi-check-square', routerLink: ['/attendance']},
                                    ],
									routerLink: ['attendance']
								},
								{
									label: 'Rote Inicial',
									icon: 'pi pi-sync',
									items: [
										{ label: 'Generar', icon: 'pi pi-cog', routerLink: ['/rote']},
										{ label: 'Visualizar', icon: 'pi pi-refresh', routerLink: ['/rote/view']},
										{ label: 'Confirmar', icon: 'pi pi-arrow-circle-up', routerLink: ['/rote/init']}
									],
									routerLink: ['attendance']
								},
								{
									label: 'Tiempo Real',
									icon: 'pi pi-play',
									items: [
										{ label: 'Rote', icon: 'pi pi-sync', routerLink: ['/rote/realtime']},
										{ label: 'Puntaje por aparato', icon: 'pi pi-star-fill', routerLink: ['/ratings/realtime']},
                                        { label: 'Puntaje general', icon: 'pi pi-star', routerLink: ['/ratings/realtime-general']},
									],
									routerLink: ['attendance']
								},
								{
									label: 'Calificacion',
									icon: 'pi pi-star',
									items: [
										{ label: 'Jueces', icon: 'pi pi-users', routerLink: ['/ratings/judges']},
										{ label: 'Visualizar', icon: 'pi pi-eye', routerLink: ['/ratings/view']},
										{ label: 'Aprobar Recalificacion', icon: 'pi pi-question', routerLink: ['/ratings/approve']},
										{ label: 'Recalificar', icon: 'pi pi-user-edit', routerLink: ['/ratings/reevaluate']},
									],
									routerLink: ['ratings']
								},
								{
									label: 'Premiación',
									icon: 'pi pi-sitemap',
									items: [
										{ label: 'Individual', icon: 'pi pi-user', items: [
											{ label: 'Crear', icon: 'pi pi-table', routerLink: ['/awards/create']},
											{ label: 'Confirmar', icon: 'pi pi-microsoft', routerLink: ['/awards/confirm']},
											{ label: 'Visualizar', icon: 'pi pi-eye', routerLink: ['/awards/view-single']},
										]},
										{ label: 'Grupal Nivel', icon: 'pi pi-users', routerLink: ['/awards/create-group']},
										{ label: 'General', icon: 'pi pi-bookmark', routerLink: ['/awards/create-general']},
									],
									routerLink: ['ratings']
								},
								{
									label: 'Reportes',
									icon: 'pi pi-file',
									items: [
										{ label: 'Rote', icon: 'pi pi-sync', routerLink: ['/reports/rote'] },
										{ label: 'General', icon: 'pi pi-list', routerLink: ['/reports/general'] },
										{ label: 'Competencia activa', icon: 'pi pi-flag', routerLink: ['/reports/competition/active'] },
										{ label: 'Competencia finalizada', icon: 'pi pi-flag-fill', routerLink: ['/reports/competition/done'] },
									]
								},
                                {
									label: 'Contador',
									icon: 'pi pi-stopwatch',
                                    items: [
										{ label: 'Contador', icon: 'pi pi-stopwatch', routerLink: ['/counter']},
                                    ],
                                    routerLink: ['/counter']
								},
								{
									label: 'Usuarios',
									icon: 'pi pi-user',
									items: [
										{ label: 'Administración de Usuarios', icon: 'pi pi-cog', routerLink: ['/users'] },
									]
								},
							];
			}else if (this.user.rol === 'Cómputo'){
				this.model = [
								{
									label: 'Competencia', icon: 'pi pi-bolt',
									items: [
										{ label: 'Administración de Competencias', icon: 'pi pi-cog', routerLink: ['/competition'] },
										{ label: 'Visualizar Información', icon: 'pi pi-database', routerLink: ['/competition/data'] },
									]
								},
                                {
									label: 'Horarios', icon: 'pi pi-calendar',
									items: [
										{ label: 'Crear Horarios', icon: 'pi pi-clock', routerLink: ['/schedules/create'] },
										{ label: 'Visualizar Información', icon: 'pi pi-database', routerLink: ['/competition/schedules'] },
									]
								},
								{
									label: 'Asistencia',
									icon: 'pi pi-check-square',
                                    items: [
										{ label: 'Asistencia', icon: 'pi pi-check-square', routerLink: ['/attendance']},
                                    ],
									routerLink: ['attendance']
								},
								{
									label: 'Rote Inicial',
									icon: 'pi pi-sync',
									items: [
										{ label: 'Generar', icon: 'pi pi-cog', routerLink: ['/rote']},
										{ label: 'Visualizar', icon: 'pi pi-refresh', routerLink: ['/rote/view']},
										{ label: 'Confirmar', icon: 'pi pi-arrow-circle-up', routerLink: ['/rote/init']}
									],
									routerLink: ['attendance']
								},
								{
									label: 'Tiempo Real',
									icon: 'pi pi-play',
									items: [
										{ label: 'Rote', icon: 'pi pi-sync', routerLink: ['/rote/realtime']},
										{ label: 'Puntaje por aparato', icon: 'pi pi-star-fill', routerLink: ['/ratings/realtime']},
                                        { label: 'Puntaje general', icon: 'pi pi-star', routerLink: ['/ratings/realtime-general']},
									],
									routerLink: ['attendance']
								},
								{
									label: 'Calificacion',
									icon: 'pi pi-star',
									items: [
										{ label: 'Aprobar Recalificacion', icon: 'pi pi-question', routerLink: ['/ratings/approve']},
									],
									routerLink: ['ratings']
								},
								{
									label: 'Premiación',
									icon: 'pi pi-sitemap',
									items: [
										{ label: 'Individual', icon: 'pi pi-user', items: [
											{ label: 'Crear', icon: 'pi pi-table', routerLink: ['/awards/create']},
											{ label: 'Confirmar', icon: 'pi pi-microsoft', routerLink: ['/awards/confirm']},
											{ label: 'Visualizar', icon: 'pi pi-eye', routerLink: ['/awards/view-single']},
										]},
										{ label: 'Grupal Nivel', icon: 'pi pi-users', routerLink: ['/awards/create-group']},
										{ label: 'General', icon: 'pi pi-bookmark', routerLink: ['/awards/create-general']},
									],
									routerLink: ['ratings']
								},
								{
									label: 'Reportes',
									icon: 'pi pi-file',
									items: [
										{ label: 'Rote', icon: 'pi pi-sync', routerLink: ['/reports/rote'] },
										{ label: 'General', icon: 'pi pi-list', routerLink: ['/reports/general'] },
									]
								},{
									label: 'Contador',
									icon: 'pi pi-stopwatch',
                                    items: [
										{ label: 'Contador', icon: 'pi pi-stopwatch', routerLink: ['/counter']},
                                    ],
                                    routerLink: ['/counter']
								},
							];
			}else if (this.user.rol === 'Asistente cómputo'){
				this.model = [
								{
									label: 'Competencia', icon: 'pi pi-bolt',
									items: [
										{ label: 'Visualizar Información', icon: 'pi pi-database', routerLink: ['/competition/data'] },
									]
								},
                                {
									label: 'Horarios', icon: 'pi pi-calendar',
									items: [
										{ label: 'Visualizar Información', icon: 'pi pi-database', routerLink: ['/competition/schedules'] },
									]
								},
								{
									label: 'Asistencia',
									icon: 'pi pi-check-square',
                                    items: [
										{ label: 'Asistencia', icon: 'pi pi-check-square', routerLink: ['/attendance']},
                                    ],
									routerLink: ['attendance']
								},
							];
			}else if (this.user.rol === 'Juez'){
				this.model = [
								{
									label: 'Calificacion',
									icon: 'pi pi-star',
									items: [
										{ label: 'Jueces', icon: 'pi pi-users', routerLink: ['/ratings/judges']},
										{ label: 'Visualizar', icon: 'pi pi-eye', routerLink: ['/ratings/view']},
										{ label: 'Recalificar', icon: 'pi pi-user-edit', routerLink: ['/ratings/reevaluate']},
									],
									routerLink: ['ratings']
								},
							];
			}else if (this.user.rol === 'Animadora competencia'){
				this.model = [
								{
									label: 'Horarios', icon: 'pi pi-calendar',
									items: [
                                        { label: 'Visualizar Información', icon: 'pi pi-database', routerLink: ['/competition/schedules'] },
									]
								},
								{
									label: 'Tiempo Real',
									icon: 'pi pi-play',
									items: [
										{ label: 'Rote', icon: 'pi pi-sync', routerLink: ['/rote/realtime']},
										{ label: 'Puntaje por aparato', icon: 'pi pi-star-fill', routerLink: ['/ratings/realtime']},
                                        { label: 'Puntaje general', icon: 'pi pi-star', routerLink: ['/ratings/realtime-general']},
									],
									routerLink: ['attendance']
								},
							];
			}else if (this.user.rol === 'Animadora premiación'){
				this.model = [
								{
									label: 'Horarios', icon: 'pi pi-calendar',
									items: [
                                        { label: 'Visualizar Información', icon: 'pi pi-database', routerLink: ['/competition/schedules'] },
									]
								},
								{
									label: 'Tiempo Real',
									icon: 'pi pi-play',
									items: [
										{ label: 'Rote', icon: 'pi pi-sync', routerLink: ['/rote/realtime']},
										{ label: 'Puntaje por aparato', icon: 'pi pi-star-fill', routerLink: ['/ratings/realtime']},
                                        { label: 'Puntaje general', icon: 'pi pi-star', routerLink: ['/ratings/realtime-general']},
									],
									routerLink: ['attendance']
								},
								{
									label: 'Premiación',
									icon: 'pi pi-sitemap',
									items: [
										{ label: 'Individual', icon: 'pi pi-user', items: [
											{ label: 'Visualizar', icon: 'pi pi-eye', routerLink: ['/awards/view-single']},
										]},
										{ label: 'Grupal Nivel', icon: 'pi pi-users', routerLink: ['/awards/create-group']},
										{ label: 'General', icon: 'pi pi-bookmark', routerLink: ['/awards/create-general']},
									],
									routerLink: ['ratings']
								},
							];
			}
		}
    }
}
