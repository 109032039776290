
import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environment';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';


@Injectable({
    providedIn: 'root',
})
export class FunctionService {
	private message: any;

	lowercaseRegex = /^(?=.*[a-z])/;
	uppercaseRegex = /^(?=.*[A-Z])/;
	digitRegex = /^(?=.*\d)/;
	specialCharRegex = /^(?=.*[@$!%*?&_-])/;
	lengthRegex = /^.{8,}$/;
	passwordValid: boolean = false;

    constructor() {

    }

   	getFormErrors(formGroup : FormGroup<any>) {
		const formErrors : {[key: string]: any} = {};
		Object.keys(formGroup.controls).forEach(key => {
			const controlErrors = [];
    		const control = formGroup.get(key);
			if (control && control.errors) {
				const c = control.errors;
				const errorMessages = Object.keys(control.errors).map(keyError => {
				  return keyError === 'required' ? this.message.portal.completar_campo :
				  keyError === 'minlength' ? this.message.portal.minimo_tamano.replace("${digit}",c["minlength"].requiredLength) :
				  keyError === 'samePassword' ? this.message.portal.contrasena_no_coincide :
				  keyError === 'emailValid' ? this.message.portal.correo_no_valido :
				  this.message.portal.campo_invalido;
				});
				controlErrors.push(...errorMessages);
			}
			if (controlErrors.length > 0) {
				formErrors[key] = controlErrors;
			  }
			});

		return formErrors;
	}

	validateNumberInput(event: KeyboardEvent) {
		const keyCode = event.keyCode;
		const valid =
		  keyCode === 8 || // Backspace
		  keyCode === 9 || // Tab
		  keyCode === 46 || // Delete
		  (keyCode >= 48 && keyCode <= 57) || // Numbers
		  (keyCode >= 96 && keyCode <= 105); // Numpad

		if (!valid) {
		  event.preventDefault();
		}
	}

	getCrypt(data: string){
		const hash = CryptoJS.SHA256(data).toString();
		return hash;
	}

	saveLocalData(response: any) {
        const data = JSON.stringify(response);
        const ecnryptData = CryptoJS.AES.encrypt(data, environment.EncryptionUser).toString();
        localStorage.setItem(environment.Key_local, ecnryptData);
    }

	getLocallData() {
        let value = localStorage.getItem(environment.Key_local);
        if (value !== null)
        {
            const decryptedBytes = CryptoJS.AES.decrypt(value, environment.EncryptionUser);
            const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
            const decryptedData = JSON.parse(decryptedString);
            return decryptedData;
        }
    }

	clearLocalData() {
        localStorage.clear();
    }

	convertDatetoString(date:any) {
        console.log(date);
        let formattedDate = '';
        return formattedDate = format(date, 'yyyy-MM-dd');
    }

	convertDatoToUTC(date: string) {

		const fechaNac = new Date(date);
		const dia = fechaNac.getUTCDate();
		const mes = fechaNac.getUTCMonth() + 1; // Se suma 1 ya que los meses en JavaScript son indexados desde 0
		const anio = fechaNac.getUTCFullYear();
        console.log(new Date(`${mes}-${dia}-${anio}`));
		return new Date(`${mes}-${dia}-${anio}`);

	}

	convertdate(date:any) {
        const fecha = new Date(date);
        const year = fecha.getUTCFullYear();
        const month = fecha.getUTCMonth() + 1;
        const day = fecha.getUTCDate();
        const hours = fecha.getUTCHours();
        const minutes = fecha.getUTCMinutes();
        return `${year}-${this.padNumber(month)}-${this.padNumber(day)} ${this.padNumber(hours)}:${this.padNumber(minutes)}`;
    }

	padNumber(num:any) {
        return num.toString().padStart(2, '0');
    }

	validatePassword(form: FormGroup, field: string): number[] {
		let list: number[] = [];
		const password = form.get(field)?.value.trim();
		if (!this.lowercaseRegex.test(password)) {
			this.addElementToList(1, list)
		}
		if (!this.uppercaseRegex.test(password)) {
			this.addElementToList(2, list)
		}
		if (!this.digitRegex.test(password)) {
			this.addElementToList(3, list)
		}
		if (!this.lengthRegex.test(password)) {
			this.addElementToList(4, list)
		}
		if (!this.specialCharRegex.test(password)) {
			this.addElementToList(5, list)
		}

		if (list.length == 0) {
			this.passwordValid = true;
		} else {
			this.passwordValid = false;
		}

		return list;
	}

	addElementToList(element: number, list: number[]){
		if (!list.includes(element)) {
			return list.push(element);
		}
		return list;
	}

	validateWeb(field:string) {
		const urlRegex = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(:\d{2,5})?(\/.*)?$/i;
		const isUrl = urlRegex.test(field);
		return isUrl;
	}

	validateEmail(control: AbstractControl, email:any) {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const field = control.parent?.get(`email`);
		if (!regex.test(field?.value)) {
			return { emailValid: false };
		} else {
			return true;
		}
	}

	getConfirmPassword(control: AbstractControl){
		const field = control.parent?.get('password');
		if (field?.value != control.value) {
		  return { samePassword: false };
		}
		return null;
	}

	generateSchoolYear() {
		const currentYear = new Date().getFullYear();
		const listaObjetos = [];

		for (let i = currentYear; i <= 2050; i++) {
		  const objeto = {
			id: i - currentYear + 1,
			name: `${i}-${i + 1}`
		  };
		  listaObjetos.push(objeto);
		}
		return listaObjetos;
	}

	saveData(data: any) {
        sessionStorage.clear();
        sessionStorage.setItem('data', data);
    }

	readXls(data:any) {
        const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'array' });
        const worksheetName: string = workbook.SheetNames[0];
        const worksheet: XLSX.WorkSheet = workbook.Sheets[worksheetName];
        const jsonData: any[] = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        return jsonData;
    }

	convertDateHMtoString(date:any) {
        let formattedDate = '';
        return formattedDate = format(date, 'HH:mm');
    }

	getData() {
        let value = sessionStorage.getItem('data');
        if (value !== null)
        {
            const data = JSON.parse(value);
            return data;
        } else {
            return {
                "email":"",
                "tipo":"",
                "user":""
            }
        }
    }

	clearData() {
        sessionStorage.clear();
    }

	isTokenExpired(token: string): boolean {
        const expirationDate = this.getTokenExpirationDate(token);
        if (!expirationDate) {
          return true;
        }
        const currentDate = new Date();
        return currentDate.getTime() >= expirationDate.getTime();
      }

      private getTokenExpirationDate(token: string): Date | null {
        const tokenParts = token.split('.');
        if (tokenParts.length !== 3) {
          return null;
        }
        const payload = JSON.parse(atob(tokenParts[1]));
        if (!payload || !payload.exp) {
          return null;
        }
        const expirationDate = new Date(payload.exp * 1000);
        return expirationDate;
    }
}
