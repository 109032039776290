import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppLayoutComponent} from './layout/app.layout.component';
import {environment} from 'src/environment';
import {AuthGuard} from "./demo/service/auth.guard";

const routes: Routes = [
    {
        path: '',
        redirectTo: localStorage.getItem(environment.Key_local)
            ? './dashboard-saas'
            : 'auth/login',
        pathMatch: 'full',
    },
    {
        path: '', component: AppLayoutComponent,
        children: [
            {
                path: '',
                canActivate: [AuthGuard],
                loadChildren: () => import('./demo/components/dashboards/dashboards.module').then(m => m.DashboardsModule)
            },
            {
                path: 'competition',
                data: { breadcrumb: 'competition' },
                canActivate: [AuthGuard],
                loadChildren: () => import('./demo/components/pages/competition/competition.module').then(m => m.CompetitionModule)
            },
            {
                path: 'schedules',
                data: { breadcrumb: 'schedules' },
                canActivate: [AuthGuard],
                loadChildren: () => import('./demo/components/pages/schedules/schedules.module').then(m => m.SchedulesModule)
            },
            {
                path: 'users',
                data: { breadcrumb: 'users' },
                canActivate: [AuthGuard],
                loadChildren: () => import('./demo/components/pages/users/users.module').then(m => m.UsersModule)
            },
            {
                path: 'attendance',
                data: { breadcrumb: 'attendance' },
                canActivate: [AuthGuard],
                loadChildren: () => import('./demo/components/pages/attendance/attendance.module').then(m => m.AttendanceModule)
            },
            {
                path: 'rote',
                data: { breadcrumb: 'rote' },
                canActivate: [AuthGuard],
                loadChildren: () => import('./demo/components/pages/rote/rote.module').then(m => m.RoteModule)
            },
            {
                path: 'ratings',
                data: { breadcrumb: 'ratings' },
                canActivate: [AuthGuard],
                loadChildren: () => import('./demo/components/pages/ratings/ratings.module').then(m => m.RatingsModule)
            },
            {
                path: 'awards',
                data: { breadcrumb: 'awards' },
                canActivate: [AuthGuard],
                loadChildren: () => import('./demo/components/pages/awards/awards.module').then(m => m.AwardsModule)
            },
            {
                path: 'reports',
                data: { breadcrumb: 'reports' },
                canActivate: [AuthGuard],
                loadChildren: () => import('./demo/components/pages/reports/reports.module').then(m => m.ReportsModule)
            },
            {
                path: 'counter',
                data: { breadcrumb: 'counter' },
                loadChildren: () => import('./demo/components/pages/counter/counter.module').then(m => m.CounterModule)
            },
            {
                path: 'errors',
                data: { breadcrumb: '' },
                loadChildren: () => import('./demo/components/pages/noauth/noauth.module').then(m => m.NoAuthModule)
            },

        ]
    },
    {
        path: 'auth',
        data: {breadcrumb: 'Auth'},
        loadChildren: () => import('./demo/components/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'notfound',
        loadChildren: () => import('./demo/components/notfound/notfound.module').then(m => m.NotfoundModule)
    },
    {path: '**', redirectTo: '/notfound'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
